import React, { useEffect } from 'react';
import { Button, TextField, Box, Typography, Container } from '@mui/material';
import Logo from "../images/favicon.png";

const QRScan = () => {
    useEffect(() => {
        // on base of devicetype you can do your logic check if from IOS or Android
        const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        console.log(isIos, "isIos")
        let IOS= `itms-apps://itunes.apple.com/app/6667113794`;
        let Android= `https://play.google.com/store/apps/details?id=com.zahrasalon`;
        window.location.href = isIos ? IOS : Android;
    }, [])
  
  return (
    <Container component="main" maxWidth="xl" sx={{
      backgroundColor: 'secondary.main',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      

    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 2,
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          width: '30%',
          height:"350px",
          maxWidth: '600px',
          marginTop:"50px",
        
          '@media (max-width: 1000px)': {
            width: '40%', // Adjust width for smaller screens
          },
          '@media (max-width: 600px)': {
            width: '50%', // Adjust width for smaller screens
          },
        }}
      >

   
      </Box>
    </Container>
  );
}

export default QRScan;
